<template>
  <div>
    <v-app-bar app color="#E3F2FD">
      <v-toolbar-title>        
      <h3 @click="dashboard()" style="cursor: pointer; margin-right: 10px;">Výkazy v. 2 (2024-10-15)</h3>        
      
       <span v-if="$store.getters.getUser" > ({{$store.getters.getUser.email}})</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
 
      <v-toolbar-items>
        <v-btn @click="register()" v-if="!isLogged">Register</v-btn>
        <v-btn @click="login()" v-if="!isLogged">Login</v-btn>
        <v-btn @click="dashboard()" v-if="isLogged">Dashboard</v-btn>
        <v-btn v-if="false && isLogged && !isAdmin" @click="mojeProjekty()" >Moje projekty</v-btn>
        <v-btn v-if="isLogged && !isAdmin" @click="rozvrhHodin()" >Moje rozvrhy hodín</v-btn>
        <v-btn v-if="isLogged" @click="infolisty()" >infolisty</v-btn>
        <v-btn v-if="isLogged" @click="onLogout">Logout</v-btn>
      </v-toolbar-items>
      
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
    </v-app-bar>
  
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

  export default {
    computed: 
        mapGetters([
                        'isAdmin',
                        'isLogged'
                    ])
  ,
  methods: {
    onLogout() {
      this.$store.dispatch('logout')
    },
    mojeProjekty() {
      this.$router.push({name:'project_list', params: {id: this.$store.getters.getUser.user_id }})     
    },
    register() {
      this.$router.push({name:'register', params: {}})     
    },
    login() {
      this.$router.push({name:'login', params: {}})     
    },
    dashboard() {
      // eslint-disable-next-line no-console
      // console.log("klikol")
      if (!this.isLogged) return
         this.$router.push({name:'dashboard', params: {}})          
    },
    rozvrhHodin() {
      this.$router.push({name:'rozvrh_hodin', params: {id: this.$store.getters.getUser.user_id }})     
    },
    infolisty() {
      this.$router.push({name:'infolisty', params: {}})     
    },

  }
}
</script>

<style>

</style>
<template>
  <div>
    <p v-if="isAdmin && !inicialisedAdmin">{{initDataA()}}</p>
    <p v-if="!isAdmin && !inicialisedUser">{{initDataU()}}</p>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card outlined color = "lightgray">
          <v-card-text>
                <p>chyby hláste <a href="mailto:jskalka@ukf.sk">jskalka@ukf.sk</a></p>               
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="this.$store.getters.isAdmin">
      <v-flex xs12 outlined>
      <v-card>
        <v-card-title>
          Zoznam priradených projektov:
        </v-card-title>
        <v-card-text>
          <v-layout row wrap v-for="(project,index) in admin_projects" :key="index" @click="openProject(project.id)" style="cursor: pointer">
            <v-flex xs1 offset-1>
             <h2> {{project.ITMS}} </h2>
            </v-flex>
            <v-flex xs1>
               <h2>{{project.akronym}}</h2>
            </v-flex>
            <v-flex xs8>
              <h2> {{project.nazov}}</h2>
            </v-flex>
            <v-flex xs12>
              <p>.</p>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="!this.$store.getters.isAdmin">    
      <v-flex xs12>
        <v-card v-if="myContent">
          <v-card-title style="background-color:lightgray">Výkazy:</v-card-title>
          <v-layout row wrap>
            <v-flex xs12 v-for="(year, index) in myContent.list" :key="index">
                  <v-card style="margin-left:5px; margin-top:10px;" raised>                    
                      <v-card-title primary-title class="layout justify-center">{{year.year}}</v-card-title>
                      <v-card-text class="layout justify-center">
                          <v-flex xs1 v-for="(month,index2) in year.months" :key="index2">
                            <v-card :color="getColorStav(month.stav_vykazu)">
                              <v-card-title class="layout justify-center"  @click="showReport(year.year, index2+1)">
                                {{index2+1}}                                
                              </v-card-title>
                              <v-card-text  @click="showReport(year.year, index2+1)">
                                <v-layout row wrap justify-center="">
                                  {{month.stav_vykazu}}  
                                </v-layout>
                                <v-layout row wrap v-for="(projekt,index3) in month.project_list" :key="index3">
                                  <v-flex xs7 offset-1>
                                      {{projekt.nazov}}
                                  </v-flex> 
                                  <v-flex xs4>
                                      {{projekt.hodin}}
                                  </v-flex> 
                                </v-layout>
                              </v-card-text>
                              <v-card-actions>
                                <v-icon v-if="month.stav_vykazu == 'uzavretý'" class="icon-unlock" @click="unlockMe(index,index2)">lock_open</v-icon>
                              </v-card-actions>
                            </v-card>
                          </v-flex>
                      </v-card-text> 
                  </v-card>
            </v-flex>
          </v-layout>
        </v-card>
        <v-card outlined>
          <v-card-title>
            Sumár projektov:
          </v-card-title>
          <v-card-text>
            <v-row v-for="(projekt,index) in mySumProjects" :key="index">
              <v-col cols=1>
              </v-col>
              <v-col cols=2>
                <b>{{projekt.nazov}}</b>
              </v-col>
              <v-col cols=9>
              
                <v-row v-for="(aktivita,index) in projekt.aktivity" :key="index">
                  <v-col cols=2></v-col>
                  <v-col cols=4>
                    {{aktivita.nazov}}
                  </v-col>
                  <v-col cols=6>
                    {{aktivita.dlzka}}
                  </v-col>
                </v-row>
              </v-col>
              </v-row>              
             
          </v-card-text>
          <v-card-actions>
            <v-layout justify-center>
              <v-flex xs12>
                <v-btn color="primary" width="100%">
                  (c) 2019-2023 Katedra informatiky FPVaI UKF v Nitre
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
  </v-layout>
  </div>
</template>

<script>
import axios from '../../axios-auth.js'
import {mapGetters} from 'vuex'

  export default {
    computed: 
        mapGetters([
                        'isAdmin'
                    ])
    ,
    data() {
      return {
        start_year: 2020,
        end_year: 2025,
        inicialisedUser: false,
        inicialisedAdmin: false,
        message : null,
        admin_projects: null,
        myProjects: null,
        mySumProjects: null,
        myContent: null,
        user_id: null
        /* years : [
          {
            rok: 2018
            months: [
              {project_list: [ 
                {
                  project_name : ITA,
                  hodin: 10
                  },
                  {
                  project_name : Fake,
                  hodin: 50
                  }
                  ]
                }
              ]
  ]
           }

        */
      }
    },
    mounted () {
      this.inicialised = false
   },
   methods: {
    initDataU() {
     this.inicialisedUser = true
     this.user_id = this.$store.getters.getUserID
     
     this.getReportsFromDB()
     this.getSumProjects()
    },    
    initDataA() {
     this.inicialisedAdmin = true
     this.getProjects() // id, nazov, ITMS
    },
    prepareTemplate(start_year, end_year) {
      this.myContent = {
                      list: []
      }
      for(let r = end_year; r >= start_year; r--) {
        this.myContent.list.push( {
                          year: r,
                          months: []
                        }
                      )
        for(let m = 1; m <= 12; m++) {
            this.myContent.list[this.end_year-r].months.push({
               stav_vykazu : 'prázdny',
               project_list: []
            })
        }
      }
    },
    getReportsFromDB() {
      var read = false
      // citanie z db
      axios.get('/get-projekty-mesiace', this.$store.getters.getAxiosConfig )
      .then((res) => {
        // set data to var
        if (read) return
        read = true
        // eslint-disable-next-line no-console
        console.log(res.data)
        if (res.data.list.length == 0)
          this.prepareTemplate(2024, 2025)
        else
          this.prepareTemplate(res.data.list[0].year, 2025)

        var _rok = 0
        var _mesiac = 0
        var najdeny = false
        this.myProjects = []
        // toto musi ist po polozkach
        for(let i = 0; i < res.data.list.length; i++) {
            _rok = res.data.list[i].year
            for (let j = 0; j < res.data.list[i].months.length; j++) {
              _mesiac = res.data.list[i].months[j].month
              this.myContent.list[this.end_year - _rok].months[_mesiac-1].stav_vykazu = res.data.list[i].months[j].stav
              for(let k = 0; k < res.data.list[i].months[j].project_list.length; k++) {
                  najdeny = false
                  for (let l = 0; l < this.myProjects.length; l++) {
                    if (this.myProjects[l].nazov == res.data.list[i].months[j].project_list[k].nazov) {
                      najdeny = true
                      this.myProjects[l].hodiny += res.data.list[i].months[j].project_list[k].hodiny
                    }
                  }
                  if (!najdeny) {
                    this.myProjects.push( {
                                  nazov: res.data.list[i].months[j].project_list[k].nazov,
                                  hodiny: res.data.list[i].months[j].project_list[k].hodiny
                            }
                          )
                  }


                this.myContent.list[this.end_year - _rok].months[_mesiac-1].project_list.push({
                          nazov: res.data.list[i].months[j].project_list[k].nazov,
                          hodin: res.data.list[i].months[j].project_list[k].hodiny
                        }
                        )
              }
            }
        }
        
      })
      .catch(error => {
          this.$store.commit('setFeedback', error)
      })
    },
    getProjects() {
      // citanie z db
      const data = {
          id : this.user_id
      }
      axios.post('/get-projekty-admina', data, this.$store.getters.getAxiosConfig )
      .then((res) => {
      // set data to var
       this.admin_projects = res.data.list   
           // eslint-disable-next-line no-console
      // console.log("citam projekty") 
      })
      .catch(error => {
          this.$store.commit('setFeedback', error)
      })
    },
    showReport(year, month) {
      let ide = year * 100 + month
      this.message = ide       
      this.$router.push({name:'report_user', params: {id: ide }})          
    },
    openProject(id) {
      localStorage.setItem('project_id', id)   
      this.$router.push({name:'project_users', params: {id: id }})  
    },
    getColorStav(stav) {
      if (stav == 'prázdny') return "#E3F2FD"
      if (stav == 'otvorený') return "#FF8A80"
      if (stav == 'uzavretý') return "yellow"
      if (stav == 'schválený') return "#76FF03"
      return "red"
    },
    unlockMe(index1,index2) {
        const data = {
            user_id: this.user_id,
            rok: this.myContent.list[index1].year,
            mesiac: index2+1,
            stav: "otvorený"
        }

       // eslint-disable-next-line no-console
       // console.log(data) 

       axios.post('/zmena-stavu-vykazu', data, this.$store.getters.getAxiosConfig )
        .then((res) => {
        // set data to var
            if (res.data.message == 'OK')
              this.myContent.list[index1].months[index2].stav_vykazu = "otvorený"
            else {
                this.$store.commit('setFeedback', res.data.status)
                this.feedback = res.data.status
            }
        })
      
    },
    getSumProjects() {
        axios.get('/get-pocty-hodin-aktivity', this.$store.getters.getAxiosConfig )
        .then((res) => {
          // set data to var
          this.mySumProjects = res.data.list
           // eslint-disable-next-line no-console
           // console.log(this.mySumProjects) 
        })
        .catch(error => {
            this.$store.commit('setFeedback', error)
        })
    }
   }
  }
</script>

<style>
.icon-unlock {
  position: absolute;
  bottom: 4px;
  right: 4px;
  cursor: pointer;
}
</style>